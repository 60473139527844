import React, { FC } from 'react';
import { CardsWrapper, CardWrapper } from './Cards.styles';

type Props = {
  type?: 'profile' | 'article';
  onClick?:any;
  className?:string;
};

export const Card: FC<Props> = ({ children, type,...rest }) => {
  return <CardWrapper type={type} {...rest}>{children}</CardWrapper>;
};

type CardsProps = {
  columns?: number;
};

export const Cards: FC<CardsProps> = ({ children, columns }) => {
  return <CardsWrapper columns={columns}>{children}</CardsWrapper>;
};
