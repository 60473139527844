import Link from 'next/link';
import React, { FC } from 'react';
import { StyledIconLink } from './Buttons.styled';
import { default as Arrow } from 'public/arrow-link.svg';

type Props = {
  href?: string;
  type?: string;
  target?:string;
};

export const IconLink: FC<Props> = ({ href, children, type,...restProps }) => {
  return (
    <Link href={href} passHref>
      <StyledIconLink {...restProps}>
        {type === 'page' && <Arrow />}
        {children}
      </StyledIconLink>
    </Link>
  );
};
