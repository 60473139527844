export function getDateTimeFormat(dateTime = new Date(), format): string {
  const getStringNumberWith0InHead = (rawNumber): any => {
    return '0' + rawNumber;
  };
  function getFormat(stringFormat = '') {
    let result = stringFormat;
    let dayNumber = dateTime.getDate();
    let monthNumber = dateTime.getMonth() + 1;
    let secconds = dateTime.getSeconds();
    let minutes = dateTime.getMinutes();
    let hours = dateTime.getHours();
    const fullYear = dateTime.getFullYear();
    if (dayNumber < 10) dayNumber = getStringNumberWith0InHead(dayNumber);
    if (monthNumber < 10) monthNumber = getStringNumberWith0InHead(monthNumber);
    if (secconds < 10) secconds = getStringNumberWith0InHead(secconds);
    if (minutes < 10) minutes = getStringNumberWith0InHead(minutes);
    if (hours < 10) hours = getStringNumberWith0InHead(hours);

    result = result.replace(/Y{4}/g, String(fullYear));
    result = result.replace(/M{2}/g, String(monthNumber));
    result = result.replace(/D{2}/g, String(dayNumber));
    result = result.replace(/h{2}/g, String(hours));
    result = result.replace(/m{2}/g, String(minutes));
    result = result.replace(/s{2}/g, String(secconds));
    return result;
  }
  return getFormat(format);
}
