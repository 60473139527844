import styled from '@emotion/styled';
import { default as Arrow } from 'public/arrow-link.svg';

export const CardsWrapper = styled.div<{ columns?: number }>(
  ({ theme, columns }) => ({
    gap: theme.spacings.l,

    [theme.mediaQueries.medium]: {
      display: 'grid',
      gridTemplateColumns: `repeat(2, 1fr)`,
      paddingBottom: theme.spacings.xl,
    },

    [theme.mediaQueries.xlarge]: {
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
    },
  }),
);

export const CardContent = styled.div<{ type?: string; align?: string }>(
  ({ theme, type, align }) => ({
    position: 'relative',
    background: theme.colors.lightGrey,
    padding: theme.spacings.l,
    paddingTop: theme.spacings.m,
    paddingBottom: theme.spacings.m,
    textAlign: align,

    [theme.mediaQueries.medium]: {
      paddingRight: theme.spacings.xxl,
    },

    [theme.mediaQueries.large]: {
      paddingRight: theme.spacings.l,
    },

    ...(type === 'hidden' && {
      opacity: 0,
      visibility: 'hidden',
      cursor: 'pointer',
      position: 'absolute',
      transition: 'all 250ms ease',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: theme.colors.main100,
      color: theme.colors.white,
      border: `6px solid ${theme.colors.main100}`,
      padding: `${theme.spacings.m}px 0`,
      overflow: 'auto',
      fontSize: 18,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',

      [theme.mediaQueries.large]: {
        paddingRight: 0,
      },

      '>div': {
        height: '90%',
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
      },
    }),

    p: {
      marginBottom: theme.spacings.m,
    },

    '*:last-child': {
      marginBottom: 0,
    },
  }),
);

export const CardWrapper = styled.div<{ type?: string }>(({ theme, type }) => ({
  position: 'relative',
  background: theme.colors.lightGrey,
  marginBottom: theme.spacings.l,

  [theme.mediaQueries.medium]: {
    marginBottom: 0,
  },

  'p:last-child': {
    margin: 0,
  },

  ':hover': {
    '[type="hidden"]': {
      opacity: 1,
      visibility: 'visible',
    },
  },

  h3: {
    marginBottom: 20,
  },

  ...(type === 'profile' && {
    [theme.mediaQueries.medium]: {
      marginBottom: 0,
    },
  }),

  ...(type === 'article' && {
    [theme.mediaQueries.medium]: {
      marginBottom: 0,
    },
  }),
}));

export const Image = styled.img({
  width: '100%',
});

export const StyledArrow = styled(Arrow)({
  position: 'absolute',
  right: 10,
  bottom: 10,
});

export const StyledContentLink = styled.a(({ theme }) => ({
  textDecoration: 'none',
  cursor: 'pointer',
  color: theme.colors.black,
}));

export const StyledMoreLink = styled.a(({ theme }) => ({
  position: 'absolute',
  right: theme.spacings.m,
  top: '50%',
  transform: 'translateY(-50%)',
  color: theme.colors.main100,
  height: 44,
  width: 44,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ImgContainer = styled.div({
  position: 'relative',
  paddingBottom: '100%',
  height: 0,
  overflow: 'hidden',

  // img: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   objectFit: 'cover',
  //   width: '100%',
  //   height: '100%'
  // },
});
