import styled from '@emotion/styled';

export const StyledIconLink = styled.a(({ theme }) => ({
  fontSize: 18,
  color: theme.colors.black,

  svg: {
    marginRight: 12,
    verticalAlign: 'bottom',
  },
}));

export const StyledArrowLink = styled.a(({ theme }) => ({
  color: theme.colors.black,

  svg: {
    marginRight: `calc(${theme.spacings.s}px * 2)`,
  },
}));

export const Button = styled.button(({ theme }) => ({
  color: theme.colors.main100,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  font: theme.fonts.body,
  padding: 0,
  marginBottom: theme.spacings.m,
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1,

  svg: {
    transform: 'rotate(180deg)',
    marginTop: 3,
    marginRight: theme.spacings.s,
  },
}));

export const SearchButton = styled.button(({ theme }) => ({
  color: theme.colors.main100,
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  font: theme.fonts.body,
  padding: 0,
  marginBottom: theme.spacings.m,
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1,
  position: 'absolute',
  right: theme.spacings.m,
  top: '50%',
  transform: 'translateY(-50%)',
}));
